<template>
  <div></div>
  <!-- <div class="bg-light">
    <div class="container">
      <div id="anmelden" class="row h-100 justify-content-center mt-5">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary">
            <div class="card-body p-5">
              <h1 class="dark-headline h2 mb-5 font-weight-bold text-uppercase text-center d-flex align-items-center justify-content-center">
                <LogoComponent :fill="'#212529'" :height="'40px'" :width="'40px'" class="mr-3" />Shop Admin
              </h1>
              <div class="alert alert-danger form-group mb-5" v-if="fehlermeldung !== ''">
                {{fehlermeldung}}
              </div>
              <form v-on:submit.prevent="anmelden">
                <div class="form-group">
                  <label>Deine E-Mail-Adresse</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-light border-right-0">
                        <fa-icon icon="user" full-width />
                      </span>
                    </div>
                    <input type="text" class="form-control border-left-0" v-model="email">
                  </div>
                </div>
                <div class="form-group mb-4">
                  <label>Dein Passwort</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-light border-right-0">
                        <fa-icon icon="key" full-width />
                      </span>
                    </div>
                    <input type="password" class="form-control border-left-0" v-model="passwort">
                  </div>
                </div>
                <div class="form-group mb-0">
                  <button :disabled="email === '' || passwort === ''" class="btn btn-primary btn-block btn-lg" type="submit">
                    <fa-icon icon="sign-in" class="mr-2" />Anmelden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
// import LogoComponent from '../components/LogoComponent.vue'

import { auth } from "../plugins/firebase"
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth"

export default {
  name: 'SignInView',
  /* components: {
    LogoComponent
  }, */
  data() {
    return {
      email: '',
      passwort: '',
      fehlermeldung: ''
    }
  },
  methods: {
    anmelden: function() {
      const emailRegExp = RegExp('^[a-z]+.[a-z]+@comline-shop.[a-z]+$')

      if (emailRegExp.test(this.email) === false) {
        this.fehlermeldung = 'Die E-Mail-Adresse ist ungültig.'
        return
      } else if (this.passwort === '') {
        this.fehlermeldung = 'Kein Passwort eingegeben.'
        return
      }

      signInWithEmailAndPassword(auth, this.email, this.passwort)
      .then(() => this.$router.replace({ name: 'DashboardView' }))
      .catch(error => {
        if (error.code === 'auth/wrong-password') this.fehlermeldung = 'Das Passwort ist ungültig.'
        else if (error.code === 'auth/user-not-found') this.fehlermeldung = 'Es existiert kein Benutzerdatensatz, der dieser Kennung entspricht.'
        else if (error.code === 'auth/user-disabled') this.fehlermeldung = 'Das Benutzerkonto wurde von einem Administrator deaktiviert.'
        else this.fehlermeldung = error.message
      })
    }
  },
    mounted() {
    let token = this.$route.params.token || 0
    signInWithCustomToken(auth, token)
    .then(()=>{
      this.$router.push({name: 'DashboardView'})
    })
    .catch(err=>{console.error(err)})
  }
}
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
.dark-headline {
  color: #212529;
}
</style>
