<template>
  <div id="create-event-component">
    <button class="btn btn-primary" v-b-modal="'create-event-modal'">
      <fa-icon :icon="['fas','plus']" class="mr-2" />Erstellen
    </button>
    <b-modal id="create-event-modal" ref="create-event-modal" title="Event erstellen" no-close-on-backdrop hide-header-close content-class="shadow" scrollable>
      <label for="theme" class="font-weight-bold">Thema</label>
      <b-form-input id="theme" v-model="theme" class="form-group" placeholder="Mein Event"></b-form-input>

      <label for="participants" class="font-weight-bold">Teilnehmer</label>
      <p class="small">
        -1 = ausgebucht<br>
        0 = keine Teilnehmerbegrenzung
      </p>
      <b-form-input id="participants" v-model.number="participants" class="form-group" type="number" min="-1"></b-form-input>

      <label for="host" class="font-weight-bold">Sprecher</label>
      <b-form-input id="host" v-model="host" class="form-group" placeholder="Max Mustermann"></b-form-input>

      <label for="language" class="font-weight-bold">Sprache</label>
      <b-form-select id="language" v-model="language" :options="languageOptions" class="form-group"></b-form-select>

      <label for="link" class="font-weight-bold">Link</label>
      <LinkComponent v-on:linkChanged="linkChanged" :linkProp="link" />

      <label for="type" class="font-weight-bold">Typ</label>
      <b-form-select id="type" v-model="type" :options="typeOptions" class="form-group"></b-form-select>

      <label for="tables" class="font-weight-bold">Tabellen</label>
      <p class="small">Mehrfachauswahl: CMD + Klick</p>
      <b-form-select id="tables" v-model="tables" :options="tablesOptions" class="form-group" multiple></b-form-select>

      <label for="date" class="font-weight-bold">Datum</label>
      <b-form-datepicker id="date" v-model="date" locale="de-DE" class="form-group"></b-form-datepicker>

      <label class="font-weight-bold">Uhrzeit</label>
      <b-form-group
        label-cols-sm="1"
        label="Von"
        label-for="from-time"
      >
        <b-form-timepicker id="from-time" v-model="fromTime" :locale="'de'"></b-form-timepicker>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label="Bis"
        label-for="to-time"
      >
        <b-form-timepicker id="to-time" v-model="toTime" :locale="'de'"></b-form-timepicker>
      </b-form-group>

      <label for="videolink" class="font-weight-bold">Videolink</label>
      <b-form-input id="videolink" v-model="videoLink" class="form-group" placeholder="https://www..."></b-form-input>

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="hideModal">Abbrechen</b-button>
        <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SaveButtonComponent from './SaveButtonComponent'
import LinkComponent from './LinkComponent'
import moment from 'moment-timezone'

import {db} from "../plugins/firebase"
import {set, ref, push, child} from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'CreateEventComponent',
  components: {
    SaveButtonComponent,
    LinkComponent
  },
  methods: {
    hideModal: function() {
      this.$refs['create-event-modal'].hide()
    },
    saveChanges: function() {
      if (
        this.type === '' ||
        this.date === '' ||
        this.fromTime === '' ||
        this.toTime === '' ||
        this.language === '' ||
        this.theme === '' ||
        this.host === '' ||
        this.participants === '' ||
        this.tables.length === 0
      ) {
        store.dispatch("fetchAlert", {
            kind: 'danger',
            message: "Eingabe unvollständig. Bitte die Eingabe-Felder überprüfen!",
            time: false
          })
        return
      }

      this.saveButtonSate = 'saving'

      const document = {
        type: this.type,
        date: this.date,
        participants: this.participants,
        fromTime: this.fromTime,
        toTime: this.toTime,
        language: this.language,
        theme: this.theme,
        host: this.host,
        link: this.link,
        videoLink: this.videoLink,
        tables: this.tables
      }
      const newPostKey = push(child(ref(db), 'events')).key;
      set(ref(db, 'events/'+newPostKey), document)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.hideModal(), 1800)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
         store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        console.error(error)
        setTimeout(() => this.saveButtonSate = '', 1800)
      })
    },
    linkChanged: function(data) {
      this.link = data
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.type = 'Messe'
      this.date = moment().tz('Europe/Berlin').format('YYYY-MM-DD')
      this.fromTime = moment().tz('Europe/Berlin').format('HH:mm')
      this.toTime = moment().tz('Europe/Berlin').add(1, 'h').format('HH:mm')
      this.language = ''
      this.theme = ''
      this.participants = 0
      this.videoLink = ''
      this.saveButtonSate = ''
      this.host = ''
      this.link = {
        target: '_blank',
        url: '',
        intValue: '',
        type: 'Extern'
      }
      this.language = 'DE'
      this.tables = []
    })
  },
  data() {
    return {
      type: 'Messe',
      typeOptions: [
        'Messe',
        'Road Show',
        'Training',
        'Webinar'
      ],
      date: moment().tz('Europe/Berlin').format('YYYY-MM-DD'),
      fromTime: moment().tz('Europe/Berlin').format('HH:mm'),
      toTime: moment().tz('Europe/Berlin').add(1, 'h').format('HH:mm'),
      language: 'DE',
      languageOptions: [
        'DE',
        'EN',
        'FR'
      ],
      theme: '',
      host: '',
      link: {
        target: '_blank',
        url: '',
        intValue: '',
        type: 'Extern'
      },
      tables: [],
      tablesOptions: [
        'Standard',
        'EDP',
        'EDP Trainings',
        'DPP',
        'DPP Trainings',
        'APP',
        'Frankreich'
      ],
      videoLink: '',
      participants: 0,
      saveButtonSate: ''
    }
  }
}
</script> 