<template>
  <div id="create-dpp-offer-component">
    <button class="btn btn-primary" v-b-modal="'create-dpp-offer-modal'">
      <fa-icon :icon="['fas', 'plus']" class="mr-2" />Erstellen
    </button>
    <b-modal
      id="create-dpp-offer-modal"
      ref="create-dpp-offer-modal"
      title="Banner erstellen"
      no-close-on-backdrop
      hide-header-close
      content-class="shadow"
      scrollable
    >
      <label for="name" class="font-weight-bold">Name</label>
      <b-form-input
        id="name"
        v-model="name"
        class="form-group"
        placeholder="DPP-Aktionsbanner"
      ></b-form-input>

      <label for="link" class="font-weight-bold">Link</label>
      <LinkComponent v-on:linkChanged="linkChanged" />

      <label for="downloadsLink" class="font-weight-bold">Downloads Link</label>
      <b-form-input
        id="downloadsLink"
        v-model="downloadsLink"
        class="form-group"
        placeholder="Downloads Link"
      ></b-form-input>

      <label class="font-weight-bold">Anzeigezeitraum</label>
      <b-form-group
        label-cols-sm="1"
        label="Von"
        label-for="datepicker-from-date"
        class="mb-2"
      >
        <b-form-datepicker
          id="datepicker-from-date"
          v-model="fromDate"
          :locale="'de-DE'"
        ></b-form-datepicker>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label="Bis"
        label-for="datepicker-to-date"
      >
        <b-form-datepicker
          id="datepicker-to-date"
          v-model="toDate"
          :locale="'de-DE'"
        ></b-form-datepicker>
      </b-form-group>

      <div>
        <p class="font-weight-bold">Aktionszeitraum ein-/ausblenden</p>
        <div class="d-flex">
          <b-form-checkbox
            id="showAktionszeitraum"
            size="md"
            v-model="showAktionszeitraum"
            switch
          ></b-form-checkbox>
          <label for="showAktionszeitraum"
            >Aktionszeitraum anzeigen
            <span v-if="showAktionszeitraum === true">&check;</span></label
          >
        </div>
      </div>

      <label class="font-weight-bold">Bild</label>
      <table class="mb-3 text-muted" cellpadding="2">
        <tbody>
          <tr>
            <td>Bildgröße (px):</td>
            <td>543x226</td>
          </tr>
          <tr>
            <td>Max. Dateigröße (mb):</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Erlaubte Dateiformate:</td>
            <td>jpg, png</td>
          </tr>
        </tbody>
      </table>
      <UploadImageComponent v-on:imageUpload="imageUploaded" :imageProp="''" />

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="hideModal"
          >Abbrechen</b-button
        >
        <SaveButtonComponent
          :saveButtonSate="saveButtonSate"
          v-on:submitSaveButton="saveChanges"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SaveButtonComponent from "./SaveButtonComponent";
import UploadImageComponent from "./UploadImageComponent";
import LinkComponent from "./LinkComponent";
import moment from "moment-timezone";

import { db } from "../plugins/firebase";
import { set, ref, push, child } from "firebase/database";

import { store } from "../plugins/store";

export default {
  name: "CreateDppOfferComponent",
  components: {
    SaveButtonComponent,
    UploadImageComponent,
    LinkComponent,
  },
  methods: {
    hideModal: function () {
      this.$refs["create-dpp-offer-modal"].hide();
    },
    imageUploaded: function (image) {
      this.imageUrl = image.url;
      this.fileName = image.fileName;
    },
    saveChanges: function () {
      if (
        this.name === "" ||
        this.fromDate === "" ||
        this.toDate === "" ||
        this.imageUrl === "" ||
        this.fileName === ""
      ) {
        store.dispatch("fetchAlert", {
            kind: 'danger',
            message: "Eingabe unvollständig. Bitte die Eingabe-Felder überprüfen!",
            time: false
          })
        return;
      }

      this.saveButtonSate = "saving";

      const document = {
        name: this.name,
        fromDate: this.fromDate,
        toDate: this.toDate,
        showAktionszeitraum: this.showAktionszeitraum,
        imageUrl: this.imageUrl,
        link: this.link,
        fileName: this.fileName,
        downloadsLink: this.downloadsLink,
      };
      const newPostKey = push(child(ref(db), "dppOffers")).key;
      set(ref(db, "dppOffers/" + newPostKey), document)
        .then(() => {
          this.saveButtonSate = "done";
          setTimeout(() => this.hideModal(), 1800);
        })
        .catch((error) => {
          this.saveButtonSate = "error";
          console.error(error);
          store.dispatch("fetchAlert", {
            kind: "danger",
            message: error,
            time: false,
          });
          setTimeout(() => (this.saveButtonSate = ""), 1800);
        });
    },
    linkChanged: function (data) {
      this.link = data;
    },
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", () => {
      this.name = "";
      this.fromDate = moment().tz("Europe/Berlin").format("YYYY-MM-DD");
      this.toDate = moment()
        .tz("Europe/Berlin")
        .add(1, "d")
        .format("YYYY-MM-DD");
      this.showAktionszeitraum = false;
      this.imageUrl = "";
      this.downloadsLink = "";
      this.link = {
        target: "_blank",
        url: "",
        intValue: "",
        type: "Extern",
      };
      this.fileName = "";
      this.saveButtonSate = "";
    });
  },
  data() {
    return {
      name: "",
      fromDate: moment().tz("Europe/Berlin").format("YYYY-MM-DD"),
      toDate: moment().tz("Europe/Berlin").add(1, "d").format("YYYY-MM-DD"),
      showAktionszeitraum: false,
      imageUrl: "",
      downloadsLink: "",
      link: {
        target: "_blank",
        url: "",
        intValue: "",
        body: "",
        subject: "",
        type: "Extern",
      },
      fileName: "",
      saveButtonSate: "",
    };
  },
};
</script>
