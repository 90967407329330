<template>
  <b-modal :id="modalId" :ref="modalId" title="Banner bearbeiten" no-close-on-backdrop hide-header-close lazy content-class="shadow" scrollable>
    <label for="name" class="font-weight-bold">Name</label>
      <b-form-input id="name" v-model="name" class="form-group" placeholder="Aktionsbanner"></b-form-input>

      <label for="link" class="font-weight-bold">Link</label>
      <LinkComponent v-on:linkChanged="linkChanged" :linkProp="link" />

      <label for="category" class="font-weight-bold">Kategorie</label>
      <b-form-select id="category" v-model="category" :options="categoryOptions" class="form-group"></b-form-select>

      <label for="position" class="font-weight-bold">Position</label>
      <b-form-select id="position" v-model="position" :options="positionOptions" class="form-group"></b-form-select>

      <label for="language" class="font-weight-bold">Sprache</label>
      <b-form-select id="language" v-model="language" :options="languageOptions" class="form-group"></b-form-select>

      <label class="font-weight-bold">Anzeigezeitraum</label>
      <b-form-group
        label-cols-sm="1"
        label="Von"
        label-for="datepicker-from-date"
      >
        <b-form-datepicker id="datepicker-from-date" v-model="fromDate" :locale="'de-DE'"></b-form-datepicker>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label="Bis"
        label-for="datepicker-to-date"
      >
        <b-form-datepicker id="datepicker-to-date" v-model="toDate" :locale="'de-DE'"></b-form-datepicker>
      </b-form-group>

      <label class="font-weight-bold">Bild</label>
      <table class="mb-3 text-muted" cellpadding="2">
        <tbody>
          <tr>
            <td>Bildgröße (px):</td>
            <td>383x144</td>
          </tr>
          <tr>
            <td>Max. Dateigröße (mb):</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Erlaubte Dateiformate:</td>
            <td>jpg, png</td>
          </tr>
        </tbody>
      </table>
      <UploadImageComponent v-on:imageUpload="imageUploaded"  :imageProp="imageUrl" />

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="hideModal">Abbrechen</b-button>
        <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
      </template>
  </b-modal>
</template>

<script>
import SaveButtonComponent from './SaveButtonComponent'
import UploadImageComponent from './UploadImageComponent'
import LinkComponent from './LinkComponent'
import moment from 'moment-timezone'

import { db } from "../plugins/firebase"
import { update, ref } from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'EditDealsAndSpeacialsComponent',
  props: {
    selectedKey: String,
    selectedItem: Object,
    modalId: String
  },
  components: {
    SaveButtonComponent,
    UploadImageComponent,
    LinkComponent
  },
  methods: {
    hideModal: function() {
      this.$refs['edit-deals-and-specials-modal'].hide()
    },
    imageUploaded: function(image) {
      this.imageUrl = image.url
      this.fileName = image.fileName
    },
    saveChanges: function() {
      if (
        this.name === '' ||
        this.category === '' ||
        this.fromDate === '' ||
        this.toDate === '' ||
        this.imageUrl === '' ||
        this.position === '' ||
        this.language === '' ||
        this.fileName === ''
      ) {
        store.dispatch("fetchAlert", {
            kind: 'danger',
            message: "Eingabe unvollständig. Bitte die Eingabe-Felder überprüfen!",
            time: false
          })
        return
      }

      this.saveButtonSate = 'saving'

      const document = {
        [this.selectedKey]: {
          name: this.name,
          category: this.category,
          fromDate: this.fromDate,
          toDate: this.toDate,
          imageUrl: this.imageUrl,
          link: this.link,
          position: this.position,
          language: this.language,
          fileName: this.fileName
        }
      }

      update(ref(db, 'dealsAndSpecials'), document)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.hideModal(), 1800)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
         store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        console.error(error)
        setTimeout(() => this.saveButtonSate = '', 1800)
      })
    },
    linkChanged: function(data) {
      this.link = data
    }
  },
  watch: {
    selectedItem: function(data) {
      this.name = data.name
      this.category = data.category
      this.fromDate = data.fromDate
      this.toDate = data.toDate
      this.imageUrl = data.imageUrl
      this.link = data.link
      this.position = data.position
      this.language = data.language
      this.fileName = data.fileName
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.saveButtonSate = ''
    })
  },
  data() {
    return {
      name: '',
      category: '',
      categoryOptions: [
        'CL Shop - Allgemein',
        'CL Shop - Audio',
        'CL Shop - Mac',
        'CL Shop - Video',
        'CL Frankreich',
        'CL Großbritannien'
      ],
      fromDate: moment().tz('Europe/Berlin').format('YYYY-MM-DD'),
      toDate: moment().tz('Europe/Berlin').format('YYYY-MM-DD'),
      imageUrl: '',
      link: {
        target: '_blank',
        url: '',
        intValue: '',
        type: 'Extern'
      },
      fileName: '',
      saveButtonSate: '',
      positionOptions: [
        'Links',
        'Rechts'
      ],
      position: '',
      languageOptions: [
        'DE',
        'EN'
      ],
      language: ''
    }
  }
}
</script>